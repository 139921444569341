<template>
  <div>
    <div class="flex justify-between">
      <h1
        class="text-xl text-grey-light font-medium font-headers mb-3 font-bold text-grey-medium-2"
        data-testid="title-header"
      >
        People /
        <span class="font-bold text-grey-dark-1"> Invite to Platform</span>
      </h1>
      <div class="flex space-x-2">
        <Button
          text="Back"
          size="medium"
          type="primary-white"
          :iconLeft="arrowLeft"
          @click.native="goBack"
        />
        <Button
          text="Finish"
          size="medium"
          type="primary"
          @click.native="finish"
        />
      </div>
    </div>
    <div class="bg-white shadow mt-3 p-3 rounded">
      <p class="font-barlow text-mdl font-bold text-grey-light mb-2">
        Email Sending Options
      </p>
      <div class="xl:w-3/6 w-full">
        <p class="text-mdh text-grey-dark-1">
          Invitation emails can either be sent right now or by scheduling a
          later date when the emails should go out to the people selected in the
          previous step.
        </p>
      </div>
      <div class="px-2 pt-3">
        <button class="mb-3 flex space-x-1 items-center" @click="toggle">
          <Checkbox isRound :active="sendNow" />
          <p class="text-grey-dark-1 text-mdh">Send now</p>
        </button>
        <button class="mb-1 flex space-x-1 items-center" @click="toggle">
          <Checkbox isRound :active="!sendNow" />
          <p class="text-grey-dark-1 text-mdh">Or schedule sending date</p>
        </button>
        <div class="pl-4">
          <DateTimePicker
            label="Date and Time"
            :isDisabled="sendNow"
            ref="date-picker"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button/Button.vue';
import arrowLeft from '@/assets/img/menu/arrow-left.svg';
import DateTimePicker from '@/components/DateTimePicker/index.vue';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'PeopleInviteEmails',
  components: { Button, DateTimePicker },
  data: () => ({ arrowLeft, disableNext: false, sendNow: false }),
  computed: {
    ...mapState({
      selectedUsersForInvite: (state) => state.people.selectedUsersForInvite,
    }),
  },
  mounted() {
    this.sendNow = true; // need sendNow to be initially false to mount Datepickers
  },
  methods: {
    ...mapActions({ sendInviteEmail: 'people/sendInviteEmail' }),
    goBack() {
      this.$router.push('/people/invite');
    },
    toggle() {
      this.sendNow = !this.sendNow;
    },
    async finish() {
      let sendDate = this.sendNow
        ? null
        : this.$refs['date-picker'].date.toISOString();
      let usersIds = this.selectedUsersForInvite.map((item) => item.id);
      await this.sendInviteEmail({ sendDate, usersIds });
      this.$router.push('/people');
    },
  },
};
</script>
